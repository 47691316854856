import { hightlightsSlides } from "../constants/index.js";
import { useEffect, useRef, useState } from "react";
import { playImg, replayImg, pauseImg } from "../utils/index.js";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

const VideoCarousel = () => {
    const videoRef = useRef([]);
    const videoSpanRef = useRef([]);
    const videoDivRef = useRef([]);

    const [video, setVideo] = useState({
        isEnd: false,
        startPlay: false,
        videoId: 0,
        isLastVideo: false,
        isPlaying: false,
    });

    const [loadedData, setLoadedData] = useState([]);
    const { isEnd, startPlay, videoId, isLastVideo, isPlaying } = video;

    // GSAP scroll trigger setup for animation (if needed)
    useGSAP(() => {
        gsap.to('#slider', {
            transform: `translateX(${-100 * videoId}%)`,
            duration: 2,
            ease: 'power2.inOut',
        });

        gsap.to("#video", {
            scrollTrigger: {
                trigger: "#video",
                toggleActions: "restart none none none",
            },
            onComplete: () => {
                setVideo((prev) => ({
                    ...prev,
                    startPlay: true,
                    isPlaying: true,
                }));
                videoRef.current[videoId]?.play(); // Ensure video is played after trigger
            },
        });
    }, [isEnd, videoId]);

    // Effect to control video play/pause based on the `startPlay` state
    useEffect(() => {
        if (loadedData.length > 3) {
            if (!isPlaying) {
                videoRef.current[videoId]?.pause();
            }
        } else {
            startPlay && videoRef.current[videoId]?.play();
        }
    }, [startPlay, videoId, isPlaying, loadedData]);

    // Handle metadata loading for the videos
    const handleLoadedMetaData = (i, e) => setLoadedData((prev) => [...prev, e]);

    // Handle various actions like play, pause, reset, etc.
    const handleProcess = (type, i) => {
        switch (type) {
            case "video-end":
                // Video selesai, pindah ke video berikutnya
                if (i + 1 < hightlightsSlides.length) {
                    setVideo((prevVideo) => ({
                        ...prevVideo,
                        videoId: i + 1, // Pindah ke video berikutnya
                        isEnd: false, // Reset status isEnd
                    }));
                } else {
                    // Jika sudah video terakhir, set isLastVideo
                    setVideo((prevVideo) => ({
                        ...prevVideo,
                        isLastVideo: true,
                    }));
                }
                break;

            case "video-last":
                setVideo((prev) => ({
                    ...prev,
                    isLastVideo: true,
                }));
                break;

            case "video-reset":
                setVideo((prev) => ({
                    ...prev,
                    isLastVideo: false,
                    videoId: 0,
                    isPlaying: false, // Reset isPlaying saat video di-reset
                }));
                break;

            case "play":
                setVideo((prev) => ({
                    ...prev,
                    isPlaying: true,
                }));
                videoRef.current[i]?.play(); // Directly play the video
                break;

            case "pause":
                setVideo((prev) => ({
                    ...prev,
                    isPlaying: false,
                }));
                videoRef.current[i]?.pause(); // Directly pause the video
                break;

            default:
                return video;
        }
    };

    // Initialize refs to have correct lengths
    useEffect(() => {
        videoDivRef.current = new Array(hightlightsSlides.length).fill(null);
        videoSpanRef.current = new Array(hightlightsSlides.length).fill(null);
    }, []);

    // Progress bar animation handling
    useEffect(() => {
        if (!videoRef.current[videoId]) return;
        const videoElement = videoRef.current[videoId];
        const span = videoSpanRef.current[videoId];
        let currentProgress = 0;

        const updateProgress = () => {
            const progress = (videoElement.currentTime / videoElement.duration) * 100;

            gsap.to(span, {
                width: `${progress}%`,
                backgroundColor: progress === 100 ? "#afafaf" : "white",
            });

            if (progress === 100 && !isLastVideo) {
                handleProcess("video-end", videoId); // Move to next video when done
            }
        };

        if (isPlaying) {
            videoElement.addEventListener("timeupdate", updateProgress);
        } else {
            videoElement.removeEventListener("timeupdate", updateProgress);
        }

        // Clean up event listener when video stops or changes
        return () => {
            videoElement.removeEventListener("timeupdate", updateProgress);
        };
    }, [isPlaying, videoId, isLastVideo]);

    return (
        <>
            <div className="flex items-center">
                {hightlightsSlides.map((list, i) => (
                    <div key={list.id} id="slider" className="sm:pr-20 pr-10">
                        <div className="video-carousel_container">
                            <div className="w-full h-full flex-center rounded-3xl overflow-hidden bg-black">
                                <video
                                    id="video"
                                    playsInline
                                    preload="auto"
                                    muted
                                    className={`${
                                        list.id === 2 && 'translate-x-44'}
                                        pointer-events-none
                                    `}
                                    ref={(el) => (videoRef.current[i] = el)}
                                    onPlay={() => {
                                        setVideo((prevVideo) => ({
                                            ...prevVideo,
                                            isPlaying: true,
                                        }));
                                    }}
                                    onLoadedMetadata={(e) => handleLoadedMetaData(i, e)}
                                    onEnded={() => handleProcess("video-end", i)} // Handle video end
                                >
                                    <source src={list.video} type="video/mp4" />
                                </video>
                            </div>

                            <div className="absolute top-12 left-[5%] z-10">
                                {list.textLists.map((text, idx) => (
                                    <p key={idx} className="md:text-2xl text-xl font-medium">
                                        {text}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="relative flex-center mt-10">
                <div className="flex-center py-5 px-7 bg-gray-300 backdrop-blur rounded-full">
                    {videoRef.current.length > 0 &&
                        videoRef.current.map((_, i) => (
                            <span
                                key={i}
                                ref={(el) => (videoDivRef.current[i] = el)}
                                className="mx-2 w-3 h-3 bg-gray-200 rounded-full relative cursor-pointer"
                            >
                                <span
                                    className="absolute h-full w-full rounded-full"
                                    ref={(el) => (videoSpanRef.current[i] = el)}
                                />
                            </span>
                        ))}
                </div>

                <button className="control-btn">
                    <img
                        src={isLastVideo ? replayImg : !isPlaying ? playImg : pauseImg}
                        alt={isLastVideo ? "replay" : !isPlaying ? "play" : "pause"}
                        onClick={
                            isLastVideo
                                ? () => handleProcess("video-reset")
                                : !isPlaying
                                    ? () => handleProcess("play", videoId)
                                    : () => handleProcess("pause", videoId)
                        }
                    />
                </button>
            </div>
        </>
    );
};

export default VideoCarousel;
